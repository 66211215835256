import React, { Component } from 'react';
import { polyfill } from 'es6-promise';
import fetch from 'isomorphic-fetch';
import { Link } from 'gatsby';
import Recaptcha from 'react-recaptcha';

polyfill();

const recaptchaKey = '6LdFTZkUAAAAAC7_cr0_7CC2D1sznA8Q--TxB7F6';

const submitForm = values => {
  return fetch(`/quote.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  }).then(response => {
    if (response.status !== 201) {
      throw new Error('Contact submit failed');
    }
  });
};

class QuoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      company: '',
      city: '',
      subject: '',
      email: '',
      message: '',
      recaptchaResponse: '',
      error: '',
      success: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      firstName,
      lastName,
      phone,
      company,
      city,
      subject,
      email,
      message,
      recaptchaResponse,
    } = this.state;

    if (
      [
        firstName,
        lastName,
        phone,
        company,
        city,
        subject,
        email,
        message,
        recaptchaResponse,
      ].some(value => value.length === 0)
    ) {
      return this.setState({
        ...this.state,
        error: 'Veuillez renseigner tous les champs',
      });
    }

    submitForm({
      firstName,
      lastName,
      phone,
      company,
      city,
      subject,
      email,
      message,
      recaptchaResponse,
    })
      .then(() =>
        this.setState({
          ...this.state,
          firstName: '',
          lastName: '',
          phone: '',
          company: '',
          city: '',
          subject: '',
          email: '',
          message: '',
          recaptchaResponse: '',
          error: '',
          success: true,
        })
      )
      .catch(() =>
        this.setState({
          ...this.state,
          error: 'Une erreur est survenue. Merci de réessayer ultérieurement.',
        })
      );
  }

  render() {
    const {
      firstName,
      lastName,
      phone,
      company,
      city,
      subject,
      email,
      message,
      error,
      success,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="contact-form">
        <div className="row mb0">
          <div className="col-sm-4">
            <input
              type="text"
              required="required"
              name="lastName"
              placeholder="Nom*"
              value={lastName}
              onChange={event =>
                this.setState({
                  lastName: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              required="required"
              name="firstName"
              placeholder="Prénom*"
              value={firstName}
              onChange={event =>
                this.setState({
                  firstName: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              required="required"
              name="company"
              placeholder="Raison Sociale*"
              value={company}
              onChange={event =>
                this.setState({
                  company: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <input
              type="email"
              required="required"
              name="email"
              placeholder="E-mail*"
              value={email}
              onChange={event =>
                this.setState({
                  email: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <input
              type="tel"
              required="required"
              name="phone"
              placeholder="Téléphone*"
              value={phone}
              onChange={event =>
                this.setState({
                  phone: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              required="required"
              name="city"
              placeholder="Ville*"
              value={city}
              onChange={event =>
                this.setState({
                  city: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-12">
            <input
              type="text"
              required="required"
              name="subject"
              placeholder="Quel est l’objet de votre demande ?*"
              value={subject}
              onChange={event =>
                this.setState({
                  subject: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
          <div className="col-sm-12">
            <textarea
              name="message"
              required="required"
              cols="40"
              rows="10"
              placeholder="Votre demande* (afin de mieux cerner vos attentes, merci de préciser le type de produit, la quantité, le format, le grammage, le lieu et la date de livraison souhaités…)"
              value={message}
              onChange={event =>
                this.setState({
                  message: event.target.value,
                  error: '',
                  success: false,
                })
              }
            />
          </div>
        </div>
        <p className="legend">* Champs obligatoires</p>
        <div className="text-center">
          <Recaptcha
            sitekey={recaptchaKey}
            verifyCallback={recaptchaResponse =>
              this.setState({ recaptchaResponse })
            }
            render="explicit"
            onloadCallback={() => console.log('')}
          />
          {error ? (
            <div className="form-error text-center primary bold">{error}</div>
          ) : null}
          {success ? (
            <div className="form-success text-center secondary bold">
              Message envoyé !
            </div>
          ) : null}
          <button className="btn btn-primary" type="submit">
            Envoyer
          </button>
          <p className="m0">
            Pour connaître et exercer vos droits, notamment de retrait de votre
            consentement à l'utilisation des données collectées par ce
            formulaire, veuillez consulter notre{' '}
            <Link to="/mentions-legales#donnees-personnelles">
              politique de confidentialité
            </Link>
            .
          </p>
        </div>
      </form>
    );
  }
}

export default QuoteForm;
