import React from 'react';
import QuoteForm from '../components/QuoteForm';
import '../components/layout.scss';
import Layout from '../components/layout';
import * as styles from './devis.module.scss';
import SEO from '../components/seo';

const DevisPage = () => (
  <Layout>
    <SEO title="Demande de devis" />

    <section>
      <div className="container">
        <div className="row mb0">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Demande de devis</h1>
            <p className="m0">
              Vous avez un besoin ou une demande spécifique ? Envoyez-nous votre
              demande de devis en remplissant le formulaire ci-dessous. <br />
              Notre service commercial reviendra vers vous dans les plus brefs
              délais en vous proposant une solution adaptée à votre besoin.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.quoteForm}`}>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="card">
              <h2 className="text-center">Envoyez-nous un message</h2>
              <QuoteForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default DevisPage;
